import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"

import { min } from "../utils/theme"

export default function ContactForm() {
  return (
    <Wrapper>
      <Form
        name="contact-us"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        action="/kiitos"
      >
        <input type="hidden" name="form-name" value="contact-us" />
        <Hidden>
          Älä täytä tätä jos olet ihminen:
          <input name="bot-field" />
        </Hidden>
        <Field>
          <Title>Toivon, että minuun ollaan yhteydessä:</Title>

          <Field>
            <FieldName>Nimi:</FieldName> <Input type="text" name="nimi" />
          </Field>
          <Field>
            <FieldName>Yritys:</FieldName> <Input type="text" name="yritys" />
          </Field>
          <Field>
            <FieldName>Tehtävä yrityksessä:</FieldName>
            <Input type="text" name="tehtava" />
          </Field>
          <Field>
            <FieldName>Puhelinnumero:</FieldName>
            <Input type="text" name="puhelin" />
          </Field>
          <Field>
            <FieldName>Sähköposti:</FieldName>
            <Input type="email" name="email" />
          </Field>
          <Field>
            <FieldName>Asiani koskee:</FieldName> <TextBox name="viesti" />
          </Field>
        </Field>
        <div>
          <WeekdayWrapper>
            <FieldName>Minut tavoittaa parhaiten:</FieldName>
            <DayWrapper>
              <Day>Ma</Day>
              <Checkbox type="checkbox" name="ma" />
            </DayWrapper>
            <DayWrapper>
              <Day>Ti</Day>
              <Checkbox type="checkbox" name="Ti" />
            </DayWrapper>
            <DayWrapper>
              <Day>Ke</Day>
              <Checkbox type="checkbox" name="ke" />
            </DayWrapper>
            <DayWrapper>
              <Day>To</Day>
              <Checkbox type="checkbox" name="to" />
            </DayWrapper>
            <DayWrapper>
              <Day>Pe</Day>
              <Checkbox type="checkbox" name="pe" />
            </DayWrapper>
            <DayWrapper>
              <Day>La</Day>
              <Checkbox type="checkbox" name="la" />
            </DayWrapper>
            <DayWrapper>
              <Day>Su</Day>
              <Checkbox type="checkbox" name="su" />
            </DayWrapper>
          </WeekdayWrapper>
          <WeekdayWrapper>
            <FieldName>Kellonaikaan: </FieldName>

            <DayWrapper>
              8 - 10
              <Checkbox type="checkbox" name="8-10" />
            </DayWrapper>
            <DayWrapper>
              10 - 12
              <Checkbox type="checkbox" name="10-12" />
            </DayWrapper>
            <DayWrapper>
              12 - 14
              <Checkbox type="checkbox" name="12-14" />
            </DayWrapper>
            <DayWrapper>
              14 - 16
              <Checkbox type="checkbox" name="14-16" />
            </DayWrapper>
            <DayWrapper>
              16 - 18
              <Checkbox type="checkbox" name="16-18" />
            </DayWrapper>
          </WeekdayWrapper>
          <Disclaimer>
            Lähettämällä lomakkeen hyväksyn, että kontaktitietoni tallennetaan
            Credos Oy:n asiakasrekisteriin.
            <br />
            Emme myy tai muuten luovuta asiakastietojamme muiden yritysten
            käyttöön
          </Disclaimer>
          <Button type="submit">Lähetä</Button>
        </div>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.section`
  min-height: 700px;
  margin: 1rem 3rem;
`

const Title = styled.span`
  font-size: 18px;
  margin-bottom: 0.8rem;
`

const Hidden = styled.label`
  display: none;
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  padding: 1rem 1.5rem;

  ${min("medium")(css`
    max-height: 700px;
    flex-wrap: wrap;
  `)}
`

const Field = styled.label`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;

  ${min("medium")(css`
    flex-basis: 50%;
    margin-right: 1rem;
  `)}
`

const FieldName = styled.span`
  margin-bottom: 0.7rem;

  ${min("medium")(css`
    margin: 0.5rem 0;
  `)};
`

const Input = styled.input`
  border: none;
  border-bottom: 1px solid;
  outline: none;
  max-width: 500px;

  &:active,
  &:focus {
    border-bottom: 1px solid cadetblue;
  }
`

const TextBox = styled.textarea`
  height: 100px;
  max-width: 500px;
  border: 1px solid black;
  outline: none;

  &:active,
  &:focus {
    border: 1px solid cadetblue;
  }
`

const WeekdayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.25rem;

  ${min("medium")(css`
    flex-basis: 50%;
    margin-right: 2rem;
    margin-top: 1rem;
  `)}
`

const DayWrapper = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100px;
  margin-bottom: 0.5rem;

  ${min("medium")(css`
    margin-bottom: 0.25rem;
    flex-basis: 50%;
  `)};
`

const Day = styled.span`
  margin-right: 0.25rem;
`
const Checkbox = styled.input`
  height: 30px;
  width: 30px;
  outline: none;

  &:active,
  &:focus {
    outline: 3px solid cadetblue;
  }

  ${min("medium")(css`
    height: 20px;
    width: 20px;
  `)}
`

const Disclaimer = styled.p`
  max-width: 500px;
`

const Button = styled.button`
  width: 200px;
  margin: 1rem 0;
  padding: 1rem;
  font-size: 18px;
  border: 1px solid black;
  background-color: white;
`
