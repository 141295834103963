import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"

const Contact = () => (
  <Layout>
    <SEO title="Ota yhteyttä" />
    <ContactForm />
  </Layout>
)

export default Contact
